<template>
  <div class="loading-screen h-screen" id="loadedIn">
    <div class="h-full items-center">
      <div class="bird-container bird-container-one">
        <div class="bird bird-one"></div>
      </div>
      <div class="bird-container bird-container-four">
        <div class="bird bird-four"></div>
      </div>
      <div class="bird-container bird-container-three">
        <div class="bird bird-three"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loadingScreen",
  data() {

  },
  mounted() {
    // Add a delay to ensure any initial styling is applied before transition starts
    setTimeout(() => {
      const contentElement = document.getElementById("loadedIn");
      if (contentElement) {
        // Apply the fade-in effect
        contentElement.style.transition = "opacity 1s ease-in-out";
        contentElement.style.opacity = "1";
      }
    }, 50); // A short delay to ensure the initial opacity is applied
  },
};
</script>

<style scoped>
#loadedIn {
  opacity: 0;
}

.loading-screen {
  opacity: 0%;
  width: 100%;
  background-color: #fff;
  /* Background color for the loading screen */
  z-index: 9999;
  /* Ensure it appears above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bird {
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/174479/bird-cells-new.svg");
  filter: invert(34%) sepia(55%) saturate(427%) hue-rotate(141deg) brightness(93%) contrast(91%);
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;

  animation-name: fly-cycle;
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
}

.bird-one {
  animation-duration: 1s;
  animation-delay: -0.5s;
}

.bird-three {
  animation-duration: 1.25s;
  animation-delay: -0.25s;
}

.bird-four {
  animation-duration: 1.1s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 40%;
  left: -3%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;

  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bird-container-one {
  animation-duration: 7s;
  animation-delay: 0s;
}

.bird-container-three {
  animation-duration: 7s;
  animation-delay: 1s;
}

.bird-container-four {
  animation-duration: 7s;
  animation-delay: 2s;
}

/* @keyframes fly-cycle {
    100%{
        background-position: -3600px 0;
    }
} */
@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }

  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }

  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }

  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }

  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}

@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }

  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }

  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }

  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }

  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }

  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }

  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }

  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
</style>