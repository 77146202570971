<template>
    <div
        class="header-background flex w-full space-x-8 lg:space-x-0 justify-between items-center header px-4 sm:px-24 lg:px-32 2xl:px-44"
        id="headerBlock">
        <!-- Left side content -->
        <div class="flex-row flex sm:justify-between lg:justify-normal">
            <div>
                <button class="lg:pr-2" @click="scrollTo('#headerBlock')">
                    <img src="../public/logo.png" width="70" alt="test" />
                </button>
            </div>
            <div class="flex items-center space-x-8">
                <button @click="scrollTo('#about')" class="headerLink block sm:hidden">About</button>
                <button @click="scrollTo('#standalone')" class="headerLink block sm:hidden">Books</button>
                <button @click="scrollTo('#about')" class="headerLink hidden sm:block">About Me</button>
                <button @click="scrollTo('#standalone')" class="headerLink hidden sm:block">Upcoming</button>
                <button @click="scrollTo('#books')" class="headerLink hidden sm:block">Books</button>
            </div>
        </div>

        <!-- Right side content -->
        <div class="flex items-center headerLink">
            <a href="https://www.instagram.com/author.n.training/" target="_blank" class="fa fa-instagram"></a>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        scrollTo(selector) {
            document.querySelector(selector).scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
};
</script>
