<template>
    <div class="base-background">
        <div class="p-16 lg:py-24 2xl:py-16 px-4 sm:px-24 lg:px-32 2xl:px-44 grid grid-cols-1 lg:grid-cols-2 gap-4 bg-gray-800"
            id="about">
            <div class="flex justify-center">
                <!-- Image -->
                <img src="../public/headerPic.png" alt="Fading Image" class="">
            </div>
            <div class="space-y-8 items-center justify-center flex flex-col px-16">
                <h1 class="text-6xln aboutText">Emilia Young</h1>
                <p class="aboutText">
                    Emilia Young is a budding fiction author with romance books ranging from fantasy to realistic
                    fiction. Her goal is to bring mental health discussions and accurate depictions of trauma healing
                    into the fiction genre one book at a time.
                </p>
                <!-- <button class="aboutText aboutButton py-1.5 px-3 rounded">About Me</button> -->
            </div>
        </div>

        <div class="highlightedProject p-8 sm:py-4 lg:py-32 px-4 sm:px-24 lg:px-32 2xl:px-96 flower-background"
            id="standalone" loading="lazy">
            <div class="opaqueBackground rounded">
                <div class="container mx-auto mt-5 flex items-center">
                    <div class="border-b-2 border-gray-400 flex-grow"></div>
                    <div class="mx-5">
                        <h1>Current Project</h1>
                    </div>
                    <div class="border-b-2 border-gray-400 flex-grow"></div>
                </div>
                <div class="flex flex-col lg:flex-row items-center lg:py-8 mx-2 md:mx-16">
                    <div class="items-center w-full lg:w-3/5 space-y-4 pb-4 lg:pb-0">
                        <h1>Mark of a Magpie</h1>
                        <p><i>One for Sorrow, Two for Joy, Three for a girl, Four for a boy…</i></p>
                        <p>
                            Ryn is a small-town thief who has a knack for being overconfident in her abilities, but by
                            day she’s a beloved tavern girl with the liver of steel that carries her reputation. On her
                            most daring break-in yet, she runs face first into the kingdom’s last living assassin,
                            Seven, and he’s just offered her the opportunity of a century: a ticket of attendance to the
                            King’s upcoming courtship gathering. It’s a partnership she’d definitely be a fool to take
                            up, but desperation fuels every decision she makes.
                        </p>
                        <p><i>Five for Silver, Six for Gold…</i></p>
                        <p>
                            Seven is set on his own plans, however much they interfere with hers, and they won’t be the
                            only ones in attendance with hidden agendas. When the castle goes into lockdown, it’s
                            survival of the cunning and silver-tongued. The rich are dropping like flies, and all
                            suspicions are on them.
                        </p>
                        <p><i>Seven for a Secret, never to be told. </i></p>
                    </div>
                    <div
                        class="w-full lg:w-2/5 pt-4 sm:pt-8 lg:pt-0 flex items-center justify-center place-items-center">
                        <div class="book-cover flex justify-center">
                            <div class="book-image-single justify-center">
                                <img src="../public/magpie-min.png" alt="Cover for Mark of a Magpie" />
                                <div class="effect"></div>
                                <div class="light"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BookReveal class="flowerBottomLeft" />
    </div>
</template>

<script>
import BookReveal from '../components/BookReveal.vue';

export default {
    name: 'App',
    components: {
        BookReveal
    }
};
</script>

<style>
.mask-image {
    mask-image: radial-gradient(circle, black 10%, transparent 200%);
}

@media (min-width: 1920px) {
    .flower-background {
        background-image: url('../public/Flower_1.png'), url('../public/Flower_2.png');
        /* Adjust paths as needed */
        background-position: -300px -400px, calc(100% + 250px) calc(100% + 30px), calc(0% - 250px) calc(100% + 50px);
        /* Positions the images */
        background-size: 900px 900px, 900px 900px, 900px 900px;
        /* Adjust size as needed */
        background-repeat: no-repeat, no-repeat, no-repeat;
        /* Prevents repeating */
        position: relative;
        /* Keeps the div's positioning context */
    }
}

@media (max-width: 1919px) and (min-width: 1024px) {
    .flower-background {
        background-image: url('../public/Flower_1.png'), url('../public/Flower_2.png');
        /* Adjust paths as needed */
        background-position: -300px -400px, calc(100% + 250px) calc(100% + 30px);
        /* Positions the images */
        background-size: 900px 900px, 900px 900px;
        /* Adjust size as needed */
        background-repeat: no-repeat, no-repeat;
        /* Prevents repeating */
        position: relative;
        /* Keeps the div's positioning context */
    }
}

@media (max-width: 1023px) {
    .flower-background {
        background-image: url('../public/Flower_1.png'), url('../public/Flower_2.png');
        /* Adjust paths as needed */
        background-position: -200px -300px, calc(100% + 150px) calc(100%);
        /* Positions the images */
        background-size: 600px 600px, 600px 600px;
        /* Adjust size as needed */
        background-repeat: no-repeat, no-repeat;
        /* Prevents repeating */
        position: relative;
        /* Keeps the div's positioning context */
    }
}

.flowerBottomLeft {
    background-image: url('../public/Flower_3.png');
    /* Adjust paths as needed */
    background-position: calc(0% - 250px) calc(100% + 150px);
    /* Positions the images */
    background-size: 900px 900px;
    /* Adjust size as needed */
    background-repeat: no-repeat;
    /* Prevents repeating */
    position: relative;
}

@media (max-width: 1919px) and (min-width: 1024px) {
    .flowerBottomLeft {
        background-image: url('../public/Flower_3.png');
        /* Adjust paths as needed */
        background-position: calc(0% - 250px) calc(100% + 150px);
        /* Positions the images */
        background-size: 750px 750px;
        /* Adjust size as needed */
        background-repeat: no-repeat;
        /* Prevents repeating */
        position: relative;
    }
}
</style>
